import React from "react";
import * as Lib from "../style/lib";
import * as OM from "../style/about-style";
import * as NM from "../style/nap-mas-style";
import { TheTeam } from "../utils/team";
import naprapatImg from "../../assets/img/naprapat-1800px.jpg";
import naprapat from '../../assets/logos/naprapat-icon.svg';
import checkicon from "../../assets/logos/check.png";

export const Naprapati: React.FC = () => {
  return (
    <>
      <NM.NaprapatImgWithOverlay src={naprapatImg}>
        <NM.AlignContainer>
          <NM.IconHeaderContainer>
            <NM.NMHeaderIcon
              src={naprapat}
              alt="Behandling" />
            <NM.NMHeader>
              Naprapat
            </NM.NMHeader>
          </NM.IconHeaderContainer>

          <Lib.CTAContainer>
            <Lib.CTAButton
              href="https://www.bokadirekt.se/places/haninge-naprapat-nordic-wellness-najaden-19603"
              aria-label="Boka tid på Haninge Naprapat Nordic Wellness Najaden"
              title="Boka tid på Haninge Naprapat Nordic Wellness Najaden"
              target="_blank"
              rel="noopener noreferrer">
              Boka Najaden
            </Lib.CTAButton>

            <Lib.CTAButton
              href="https://www.bokadirekt.se/places/haninge-naprapat-core-gym-vega-28270"
              aria-label="Boka tid på Haninge Naprapat Core Gym Vega"
              title="Boka tid på Haninge Naprapat Core Gym Vega"
              target="_blank"
              rel="noopener noreferrer">
              Boka Vega
            </Lib.CTAButton>
          </Lib.CTAContainer>
        </NM.AlignContainer>
      </NM.NaprapatImgWithOverlay>

      <NM.CenterDivForInformation>

        <NM.NapMasContainer>
          <Lib.Header2>
            Varför uppsöka en naprapat?
          </Lib.Header2>
          <NM.NMIngressParagraph>
            En naprapat förebygger, utreder och behandlar funktionsrubbningar och smärttillstånd i rörelse-
            och stödorganen. Vi på Haninge Naprapat är specialister på muskel- och ledbehandling.
            <br /> <br />
            När samspelet mellan muskler, leder och nervsystem inte fungerar normalt uppstår smärta.
            Genom att man behandlar både muskler och leder uppnås en bättre funktion och rörlighet i hela kroppen.
            Vi samarbetar med flera försäkringsbolag för de som har en privat vårdförsäkring.
          </NM.NMIngressParagraph>

          <NM.MethodsContainer>
            <NM.ColumnContainer>
              <Lib.Header2>
                Behandlingsmetoder
              </Lib.Header2>
              <NM.NapMasParagraph>
                Behandlingstekniker som används är ledmanipulation och mobilisering samt massage, triggerpunktsbehandling och stretching.
                Även TENS och akupunktur/ dry needling tillämpas.
                För ett varaktigt resultat kan behandlingen följas av ett individuellt träningsprogram.
                Vi på Haninge Naprapat AB kan även erbjuda dig stötvågsbehandling (Shockwave) för behandling av problematik i muskler och senfästen
              </NM.NapMasParagraph>
            </NM.ColumnContainer>

            <NM.ColumnContainer>
              <Lib.Header2>
                Vanliga sökorsaker
              </Lib.Header2>
              <NM.IconList icon={<img src={checkicon} alt="Check icon" />}>
                <span>Nackspärr</span>
                <span>Smärta och stelhet i nacke, axlar</span>
                <span>Spänningshuvudvärk</span>
                <span>Ländryggssmärta</span>
                <span>Musarm / Tennisarmbåge</span>
                <span>Hopparknä</span>
                <span>Löparknä</span>
                <span>Ischias</span>
              </NM.IconList>
            </NM.ColumnContainer>
          </NM.MethodsContainer >
        </NM.NapMasContainer>

      </NM.CenterDivForInformation>

      <NM.NMGroupContainer>
        <OM.TeamStandup>
          <Lib.Header3>Våra naprapater</Lib.Header3>
          <OM.TeamGrid>
            {TheTeam
              .filter(member => member.behandling === 'Naprapati')
              .map((member) => (
                <OM.TeamCard
                  key={member.email}
                  name={member.name}
                  education={member.education}
                  text={member.text}
                  img={member.img}
                  email={member.email}
                />
              ))}
          </OM.TeamGrid>
        </OM.TeamStandup>
      </NM.NMGroupContainer>

    </>
  );
}
