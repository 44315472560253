import React from "react";
import styled from "styled-components";
import topimg from '../../assets/img/ryggrad-2000px.jpg';
import * as Lib from "../style/lib";
import arrowImg from '../../assets/logos/arrow-down.svg';


const TopSection = styled.div`
  position: relative;
  height: 100vh;
  background-image: url(${topimg});
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
`;

const Content = styled.div`
  position: relative;
  z-index: 2;
`;



export const Introduction: React.FC = () => {
  return (
    <TopSection id="bokabehandling">
      <Lib.WhiteOverlay />
      <Content>

        <Lib.Header1>
          Vi tar hand om<br />
          dina problem
        </Lib.Header1>


        <Lib.CTAContainer>
          <Lib.CTAButton
            href="https://www.bokadirekt.se/places/haninge-naprapat-nordic-wellness-najaden-19603"
            aria-label="Boka tid på Haninge Naprapat Nordic Wellness Najaden"
            title="Boka tid på Haninge Naprapat Nordic Wellness Najaden"
            target="_blank"
            rel="noopener noreferrer">
            Boka Najaden
          </Lib.CTAButton>

          <Lib.CTAButton
            href="https://www.bokadirekt.se/places/haninge-naprapat-core-gym-vega-28270"
            aria-label="Boka tid på Haninge Naprapat Core Gym Vega"
            title="Boka tid på Haninge Naprapat Core Gym Vega"
            target="_blank"
            rel="noopener noreferrer">
            Boka Vega
          </Lib.CTAButton>
        </Lib.CTAContainer>

      </Content>
      <Lib.Arrow src={arrowImg} alt="Scroll to top" />
    </TopSection>
  );
};
