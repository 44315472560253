import React from "react";
import naprapat from '../../assets/logos/naprapat-icon.svg';
import massage from '../../assets/logos/massage-icon.svg';
import * as Treat from "../style/treatment-style";
import * as Lib from "../style/lib";



export const Treatments: React.FC = () => {
  return (
    <Treat.TreatmentBG>
      <Lib.WhiteOverlay />
      <Treat.TreatmentContent>

        <Treat.TreatmentContainer>
          <Treat.IconContainer>
            <Treat.TreatmentIcon
              src={naprapat}
              alt="Behandling" />
            <Treat.Header3>
              Naprapat
            </Treat.Header3>
          </Treat.IconContainer>
          <Treat.TreatmentParagraph>
            När samspelet mellan muskler, leder och nervsystem inte fungerar normalt
            uppstår smärta. Genom att man behandlar både muskler och leder uppnås en
            bättre funktion och rörlighet i hela kroppen.
          </Treat.TreatmentParagraph>

          <Treat.ReadMoreArrow
            to="/naprapat"
            text="Läs mer" />
        </Treat.TreatmentContainer>

        <Treat.TreatmentContainer>
          <Treat.IconContainer>
            <Treat.TreatmentIcon
              src={massage}
              alt="Behandling" />
            <Treat.Header3>
              Massage
            </Treat.Header3>
          </Treat.IconContainer>
          <Treat.TreatmentParagraph>
            Vi erbjuder massage på båda våra anläggningar. Våra massageterapeuter är
            certifierade och de skräddarsyr behandlingen efter dina önskemål.
          </Treat.TreatmentParagraph>

          <Treat.ReadMoreArrow
            to="/massage"
            text="Läs mer" />
        </Treat.TreatmentContainer>

      </Treat.TreatmentContent>
    </Treat.TreatmentBG>
  );
}
