import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import behandlingImg from '../../assets/img/arm-behandling.jpg';
import arrowleft from '../../assets/logos/arrow-left.svg';


export const TreatmentBG = styled.div`
  position: relative;
  height: 100vh;
  background-image: url(${behandlingImg});
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
`;

export const TreatmentContent = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 2;

  @media (min-width: 1024px) {
    flex-wrap: nowrap;
    gap: 1rem;
    padding: 0 2rem;
  }
`;

export const TreatmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  margin: 2rem 2rem;
  max-width: 300px;

  @media (min-width: 725px) {
    max-width: 370px;
    margin: 2rem 2rem;
  }

  @media (min-width: 1024px) {
    max-width: 460px;
    height: 278px;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 26px;
  `;

export const TreatmentIcon = styled.img`
  width: 60px; 
  height: auto; 
  margin-right: 1rem;
  `;

export const Header3 = styled.h2`
  font-size: 26px;
  font-weight: 700;
  text-align: left;
  color: var(--main-dark);
  margin-top: 8px;

  @media (min-width: 768px) {
    font-size: 30px;
  }

  @media (min-width: 1024px) {
    font-size: 36px;
  }
`;

export const TreatmentParagraph = styled.p`
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 1.5rem;
  text-align: left;

  @media (min-width: 768px) {
    font-size: 18px;
  }

  @media (min-width: 1024px) {
    font-size: 20px;
  }
`;

// Arrow bouncing
const bounce = keyframes`
  0% { transform: translateX(0); }
  50% { transform: translateX(10px); }
  100% { transform: translateX(0); }
`;

const ReadMoreContainer = styled.div`
  display: flex;
  margin-top: auto;
  `;

const ReadMore = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit; 
  font-size: 16px;
  font-weight: 400;
  transition: color 0.3s ease;

  &:hover {
    color: var(--header); 
  }

  @media (min-width: 768px) {
    font-size: 20px;
  }

  @media (min-width: 1024px) {
    font-size: 20px;
  }
`;

const Arrow = styled.img.attrs({ src: arrowleft })`
  display: inline-block;
  width: 10px; 
  height: 10px;
  margin-left: 6px;
  margin-top: 3px;
  transition: transform 0.3s ease;

  ${ReadMore}:hover & {
    animation: ${bounce} 1s ease infinite;
  }

  @media (min-width: 768px) {
    margin-left: 8px;
    width: 14px; 
    height: 13px;
  }
`;

export const ReadMoreArrow = ({ to, text }: { to: string; text: string }) => (
  <ReadMoreContainer>
    <ReadMore to={to}>
      {text}
      <Arrow />
    </ReadMore>
  </ReadMoreContainer>
);