import React from "react";
import styled from "styled-components";
import * as Lib from "../style/lib";
import IntroImg from '../../assets/img/IntroImg.png';

const IntroContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--main-light);
  padding: 1rem;
  flex-wrap: wrap;
  
  /* Tablet styles */
  @media (min-width: 768px) {
    padding: 2rem; 
  }

  /* Desktop styles */
  @media (min-width: 1024px) {
    padding: 6rem; 
  }
`;

const IntroContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  padding: 2rem 0 50px 0;

    /* Tablet styles */
    @media (min-width: 768px) {
      padding: 2rem 0 60px 0;
  }

  /* Desktop styles */
  @media (min-width: 1024px) {
    padding: 6rem 1.5rem 6rem 0; 
  }
`;



const CollageImg = styled.img`
  width: 100%; 
  height: auto; 


    /* Tablet styles */
    @media (min-width: 768px) and (max-width: 1023px) {
    max-width: 600px; 
    height: auto; 
  
  }
  
  /* Desktop styles */
  @media (min-width: 1024px) {
    width: 600px; 
    height: auto;
  }
`;


export const AboutIntroduction: React.FC = () => {
  return (
    <IntroContainer>
      <IntroContainerColumn>
        <Lib.Header2>
          Haninges mest erfarna naprapater
        </Lib.Header2>
        <Lib.BreadParagraph>
          Välkommen till oss på Haninge Naprapat AB, – din naprapat i Haninge sedan 1984.
          Vi erbjuder både naprapati och massage, så till oss kommer du för få hjälp med ditt
          smärttillstånd eller för en välgörande massage.
          <br /> <br />
          Vi behandlar och hjälper dig med besvär som nackspärr, spänningshuvudvärk, ryggskott,
          ischias, tennisarmbåge, hälsporre eller hopparknä.
        </Lib.BreadParagraph>
        <Lib.Header5>
          Välkommen!
        </Lib.Header5>

        <Lib.RedDirectButton
          to="/#bokabehandling"
          aria-label="Boka tid för behandling hos Haninge Naprapat">
          Boka tid
        </Lib.RedDirectButton>
      </IntroContainerColumn>
      <CollageImg
        src={IntroImg}
        alt="Haninge Naprapat och massage behandlingar" />
    </IntroContainer>
  );
}
