import React from "react";
import * as Lib from "../style/lib";
import * as LocationLib from "../style/location-style";
import { ReactComponent as PhoneIcon } from '../../assets/logos/phone.svg';
import { ReactComponent as EmailIcon } from '../../assets/logos/email.svg';
import { ReactComponent as GeoIcon } from '../../assets/logos/geo.svg';
import { AdvancedMarker, APIProvider, Map } from '@vis.gl/react-google-maps';

const positionVega = { lat: 59.18650473449927, lng: 18.14456637018662 };
const positionNajaden = { lat: 59.16791304989738, lng: 18.13639884056622 };
//const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const apiKey = "AIzaSyAJJXefcX1QX19ujDuQpq_F7gF9V4AnETA"

export const Location: React.FC = () => {

  return (
    <LocationLib.LocationContainer id="kontakta-oss">
      <Lib.Header2 $textAlign="center">
        Här hittar ni oss
      </Lib.Header2>
      <Lib.BreadParagraph $textAlign="center" $DmaxWidth="780px">
        Haninge Naprapat AB finns på två anläggningar i Haninge – Nordic Wellness Najaden och Core Gym Vega.
        Ni hittar entrén till oss via gymreceptionerna på båda anläggningar. Det finns goda parkeringsmöjligheter i
        närheten av våra anläggningar, och enkel tillgång till kollektivtrafik.
        <br /> <br />
        Du bokar din tid lättast online, men du kan även ringa till oss. Telefonerna är igång måndag-fredag 8:00-18:00,
        och vi svarar när vi inte är upptagna med behandlingar. Vi har kvällstider måndag - fredag.
      </Lib.BreadParagraph>

      <LocationLib.LocationRow>
        <LocationLib.LocationItem
          Icon={PhoneIcon}
          mainText="08 - 777 06 70"
          subText="Växeltid 08.00 - 18.00"
          linkType="phone"
        />

        <LocationLib.LocationItem
          Icon={EmailIcon}
          mainText="info@haningenaprapat.se"
          linkType="email"
        />

        <LocationLib.LocationItem
          Icon={GeoIcon}
          mainText={["Expressvägen 7", "136 49 Vega"]}
          subText="Fakturaadress"
          linkType="address"
        />
      </LocationLib.LocationRow>

      <LocationLib.LocationRow>

        <LocationLib.GeoColumn>
          <LocationLib.MapDiv>
            <APIProvider apiKey={apiKey!}>
              <Map
                defaultCenter={positionNajaden}
                defaultZoom={17}
                mapId="af50adad42636c86"
              >
                <AdvancedMarker
                  position={positionNajaden}
                />
              </Map>
            </APIProvider>
          </LocationLib.MapDiv>
          <LocationLib.LocationHeader4>
            Haninge Naprapat – Najaden
          </LocationLib.LocationHeader4>
          <Lib.BreadParagraph $textAlign="center">
            Handens Stationsväg 27, Handen
          </Lib.BreadParagraph>
        </LocationLib.GeoColumn>

        <LocationLib.GeoColumn>
          <LocationLib.MapDiv>
            <APIProvider apiKey={apiKey!}>
              <Map
                defaultCenter={positionVega}
                defaultZoom={17} mapId="af50adad42636c86">
                <AdvancedMarker position={positionVega} />
              </Map>
            </APIProvider>
          </LocationLib.MapDiv>
          <LocationLib.LocationHeader4>
            Haninge Naprapat – Core Gym Vega
          </LocationLib.LocationHeader4>
          <Lib.BreadParagraph $textAlign="center">
            Vega Allé 2, 126 47 Haninge
          </Lib.BreadParagraph>
        </LocationLib.GeoColumn>

      </LocationLib.LocationRow>


    </LocationLib.LocationContainer >
  );
}
